import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import useMediaQuery from "../../hooks/useMediaQuery"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { Link } from "gatsby"

const Values = () => {
  const isDesktop = useMediaQuery("(min-width: 767px)")
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      700: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }
  return (
    <div className="qvalues">
      {isDesktop ? (
        <div className="row valueswrapper">
          {[
            {
              link: "/application-security-audit/",
              imageSrc:
                "https://cdn.sanity.io/images/55lcecww/production/69abf28c7ebd87803448186c17a1bcd396b6ab1f-64x64.png",
              imageAlt: "App Security Audit",
              title: "App Security Audit",
              description:
                "From access control to data security, we find and fix vulnerabilities in your entire tech stack.",
            },
            {
              link: "/application-health-audit/",
              imageSrc:
                "https://cdn.sanity.io/images/55lcecww/production/d1889f9f2767628a952bf2329358f0e97d181291-64x64.png",
              imageAlt: "Application Health Audit",
              title: "Application Health Audit",
              description:
                "Find and eliminate application inefficiencies to improve application performance and outcomes.",
            },
            {
              link: "/post-launch-enablement-services/",
              imageSrc:
                "https://cdn.sanity.io/images/55lcecww/production/366dff93c602973e7d2b459616646551f965177b-64x64.png",
              imageAlt: "Post-Launch Enablement",
              title: "Post-Launch Enablement",
              description:
                "Get application support, documentation, and user training to maximize application business value.",
            },
          ].map(({ link, imageSrc, imageAlt, title, description }, index) => (
            <div className="col-md-4 mb-5" key={index}>
              <div className="rounded shadow card-shadow d-flex flex-column h-100">
                <Link to={link} className="flex-grow-1 d-flex flex-column">
                  <div style={{ height: "3.5rem" }} className="text-center">
                    <img
                      placeholder="blurred"
                      className="img-fluid"
                      src={imageSrc}
                      alt={imageAlt}
                      layout="constrained"
                      loading="lazy"
                      fetchpriority="low"
                      style={{ maxHeight: "100%" }}
                    />
                  </div>
                  <p className="h5 text-black pb-2 font-weight-bold text-center">
                    {title}
                  </p>
                  <p className="text-center flex-grow-1">{description}</p>
                </Link>
                <Link
                  className="btn btn-primary btn-arrow mt-3 align-self-center"
                  to={link}
                >
                  Learn More
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mobile-swiper pb-lg-5 valueswrapper d-flex flex-column">
          <LazyLoadComponent>
            <div className="">
              {/* <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <StaticImage
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/787af32323915f419dbee4645e02b29825632181-267x233.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/366dff93c602973e7d2b459616646551f965177b-64x64.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Long-Term <br />
                      Partners
                    </p>
                    <p className="text-black">
                      Our goal is to be with you for the long road, helping you
                      continuously improve your business.
                    </p>
                  </div>
                </div> */}
              <div className="col-md-4 mb-5">
                <Link to="/application-security-audit/">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/d1889f9f2767628a952bf2329358f0e97d181291-64x64.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      App Security Audit
                    </p>
                    <p>
                      From small issues to potentially catastrophic failures,
                      we find and prioritize security vulnerabilities in your
                      entire tech stack.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="">
              <div className="col-md-4 mb-5">
                <Link to="/application-health-audit/">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/69abf28c7ebd87803448186c17a1bcd396b6ab1f-64x64.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Application Health Audit
                    </p>
                    <p>
                      Find and eliminate application inefficiencies to improve
                      application performance and outcomes.
                    </p>
                  </div>
                </Link>
              </div>
              {/* <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/f8311e0d17b359fb509172781319ffced62c8fa9-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Superior <br /> Analytics
                    </p>
                    <p>
                      We provide clear dashboarding that connects disparate data
                      streams into a single, clear portal for actionable
                      insights.
                    </p>
                  </div>
                </div> */}
            </div>
            <div className="">
              <div className="col-md-4 mb-5">
                <Link to="/post-launch-enablement-services/">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/366dff93c602973e7d2b459616646551f965177b-64x64.png"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Post-Launch Enablement
                    </p>
                    <p>
                      Get application support, documentation, and user
                      training to maximize application business value.
                    </p>
                  </div>
                </Link>
              </div>
              {/* <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/ed8a6e0769aedaeae0303f8c1b79a9728947a4b2-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold header-services">
                      Cost
                      <br />
                      Reduction
                    </p>
                    <p>
                      Our automations and integrations eliminate boring,
                      monotonous tasks, saving you time, energy, and money.
                    </p>
                  </div>
                </div> */}
            </div>
            {/* <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/1cac68b88140ec4e3186f8f49f95394bd4e5dd65-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold header-services">
                      Smarter Business <br />
                      Processes
                    </p>
                    <p>
                      We help you streamline your workflows by removing
                      bottlenecks and slowdowns, getting you to market faster.
                    </p>
                  </div>
                </div>
              </div> */}
            {/* <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/bfd8196be2da318dce3f85f5645bb45b74f50795-64x61.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold">
                      Fewer <br />
                      Risks
                    </p>
                    <p>
                      We build secure, custom applications and integrations that
                      reduce errors while keeping your sensitive data safe.
                    </p>
                  </div>
                </div>
              </div> */}
            {/* <div className="swiper-slide">
                <div className="col-md-4 mb-5">
                  <div className="rounded">
                    <div style={{ height: "3.5rem" }} className="img-bg">
                      <img
                        placeholder="blurred"
                        className="img-fluid"
                        src="https://cdn.sanity.io/images/55lcecww/production/a3b310643be17572424e184a30dbbb1903899d71-64x64.png?w=60"
                        alt="Perspective"
                        layout="constrained"
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </div>
                    <p className="h5 text-black pb-2 font-weight-bold header-services">
                      Effective Change <br />
                      Management
                    </p>
                    <p>
                      Our consultants will work with your team to onboard new
                      systems, ensuring adoption and improvement.
                    </p>
                  </div>
                </div>
              </div> */}
          </LazyLoadComponent>
        </div>
      )}
    </div>
  )
}
export default Values
